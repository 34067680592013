export const initialListingModel = (limit = 10, params = {}) => {
  return {
    page: 1,
    limit,
    list: [],
    pending: false,
    error: "",
    total: 0,
    pagesCount: 0,
    params,
  };
};
export const isInt = (n) => parseInt(n) === n;
export const isFloat = (n) => {
  return typeof n === "number" && !isInt(n);
};

/**
 * Возвращает правильный падеж для числительного
 * @param {string[]} titles - массив строк [ключ, ключа, ключей] lenght = 3
 * @param {number} num - число
 * @return string
 */
export const numCases = (titles, num) => {
  // если число - не целое то вернем сразу 2 ключ, так как
  // дробное число родительный падеж (-десятых, -сотых, -тысячных).
  if (isFloat(num)) return titles[1];

  // Эта часть просто спизжена от куда-то =)
  const cases = [2, 0, 1, 1, 1, 2];
  const val = Math.abs(num);
  const current =
    val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5];
  return titles[current];
};

export const getFullName = (user) => {
  if (!user) return "";
  return [user.last_name, user.first_name, user.middle_name]
    .filter(Boolean)
    .join(" ");
};
/**
 * @param {number} ms - Время задержки в milliseconds
 * @returns {Promise<void>}
 */
export const delay = (ms = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
